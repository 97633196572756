body {
  padding: 0;
}

.no-p {
  padding: 0;
}

.no-p-t {
  padding-top: 0;
}

.no-p-r {
  padding-right: 0;
}

.no-p-b {
  padding-bottom: 0;
}

.no-p-l {
  padding-left: 0;
}

.no-p-v {
  padding: 0 0;
}

.no-p-h {
  padding: 0 0;
}

.p-5 {
  padding: 5px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-b-5 {
  padding-bottom: 5px;
}

.p-l-5 {
  padding-left: 5px;
}

.p-v-5 {
  padding: 5px 0;
}

.p-h-5 {
  padding: 0 5px;
}

.p-10 {
  padding: 10px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-v-10 {
  padding: 10px 0;
}

.p-h-10 {
  padding: 0 10px;
}

.p-15 {
  padding: 15px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-b-15 {
  padding-bottom: 15px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-v-15 {
  padding: 15px 0;
}

.p-h-15 {
  padding: 0 15px;
}

.p-20 {
  padding: 20px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-v-20 {
  padding: 20px 0;
}

.p-h-20 {
  padding: 0 20px;
}

.p-25 {
  padding: 25px;
}

.p-t-25 {
  padding-top: 25px;
}

.p-r-25 {
  padding-right: 25px;
}

.p-b-25 {
  padding-bottom: 25px;
}

.p-l-25 {
  padding-left: 25px;
}

.p-v-25 {
  padding: 25px 0;
}

.p-h-25 {
  padding: 0 25px;
}

.p-30 {
  padding: 30px;
}

.p-t-30 {
  padding-top: 30px;
}

.p-r-30 {
  padding-right: 30px;
}

.p-b-30 {
  padding-bottom: 30px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-v-30 {
  padding: 30px 0;
}

.p-h-30 {
  padding: 0 30px;
}

.p-35 {
  padding: 35px;
}

.p-t-35 {
  padding-top: 35px;
}

.p-r-35 {
  padding-right: 35px;
}

.p-b-35 {
  padding-bottom: 35px;
}

.p-l-35 {
  padding-left: 35px;
}

.p-v-35 {
  padding: 35px 0;
}

.p-h-35 {
  padding: 0 35px;
}

.p-40 {
  padding: 40px;
}

.p-t-40 {
  padding-top: 40px;
}

.p-r-40 {
  padding-right: 40px;
}

.p-b-40 {
  padding-bottom: 40px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-v-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.p-h-40 {
  padding: 0 40px;
}

.p-45 {
  padding: 45px;
}

.p-t-45 {
  padding-top: 45px;
}

.p-r-45 {
  padding-right: 45px;
}

.p-b-45 {
  padding-bottom: 45px;
}

.p-l-45 {
  padding-left: 45px;
}

.p-v-45 {
  padding: 45px 0;
}

.p-h-45 {
  padding: 0 45px;
}

.p-50 {
  padding: 50px;
}

.p-t-50 {
  padding-top: 50px;
}

.p-r-50 {
  padding-right: 50px;
}

.p-b-50 {
  padding-bottom: 50px;
}

.p-l-50 {
  padding-left: 50px;
}

.p-v-50 {
  padding: 50px 0;
}

.p-h-50 {
  padding: 0 50px;
}

.p-55 {
  padding: 55px;
}

.p-t-55 {
  padding-top: 55px;
}

.p-r-55 {
  padding-right: 55px;
}

.p-b-55 {
  padding-bottom: 55px;
}

.p-l-55 {
  padding-left: 55px;
}

.p-v-55 {
  padding: 55px 0;
}

.p-h-55 {
  padding: 0 55px;
}

.p-60 {
  padding: 60px;
}

.p-t-60 {
  padding-top: 60px;
}

.p-r-60 {
  padding-right: 60px;
}

.p-b-60 {
  padding-bottom: 60px;
}

.p-l-60 {
  padding-left: 60px;
}

.p-v-60 {
  padding: 60px 0;
}

.p-h-60 {
  padding: 0 60px;
}

.p-65 {
  padding: 65px;
}

.p-t-65 {
  padding-top: 65px;
}

.p-r-65 {
  padding-right: 65px;
}

.p-b-65 {
  padding-bottom: 65px;
}

.p-l-65 {
  padding-left: 65px;
}

.p-v-65 {
  padding: 65px 0;
}

.p-h-65 {
  padding: 0 65px;
}

.p-70 {
  padding: 70px;
}

.p-t-70 {
  padding-top: 70px;
}

.p-r-70 {
  padding-right: 70px;
}

.p-b-70 {
  padding-bottom: 70px;
}

.p-l-70 {
  padding-left: 70px;
}

.p-v-70 {
  padding: 70px 0;
}

.p-h-70 {
  padding: 0 70px;
}

.p-75 {
  padding: 75px;
}

.p-t-75 {
  padding-top: 75px;
}

.p-r-75 {
  padding-right: 75px;
}

.p-b-75 {
  padding-bottom: 75px;
}

.p-l-75 {
  padding-left: 75px;
}

.p-v-75 {
  padding: 75px 0;
}

.p-h-75 {
  padding: 0 75px;
}

.p-80 {
  padding: 80px;
}

.p-t-80 {
  padding-top: 80px;
}

.p-r-80 {
  padding-right: 80px;
}

.p-b-80 {
  padding-bottom: 80px;
}

.p-l-80 {
  padding-left: 80px;
}

.p-v-80 {
  padding: 80px 0;
}

.p-h-80 {
  padding: 0 80px;
}

.no-padding {
  padding: 0 !important;
}

.no-pad-lr,
.no-pad-rl {
  padding-left: 0;
  padding-right: 0;
}

.no-pad-tb,
.no-pad-bt {
  padding-top: 0;
  padding-bottom: 0;
}

.pad-t-only {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.pad-b-only {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

.pad-l-only {
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.pad-r-only {
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}
