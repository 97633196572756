body {
  table {
    border: 1px solid rgba($color: #000000, $alpha: 0.12);
  }
}

.no-border {
  border: none;
}

.no-border-t {
  border-top: none;
}

.no-border-r {
  border-right: none;
}

.no-border-b {
  border-bottom: none;
}

.no-border-l {
  border-left: none;
}

.no-border-lr,
.no-border-rl {
  border-left: none;
  border-right: none;
}

.no-border-tb,
.no-border-bt {
  border-top: none;
  border-bottom: none;
}

.no-border-tl,
.no-border-lt {
  border-top: none;
  border-left: none;
}

.no-border-tr,
.no-border-rt {
  border-top: none;
  border-right: none;
}

.no-border-bl,
.no-border-lb {
  border-bottom: none;
  border-left: none;
}

.no-border-br,
.no-border-rb {
  border-bottom: none;
  border-right: none;
}

.border-dashed {
  border-style: dashed;
}

.border-dotted {
  border-style: dashed;
}

.border-black {
  border-color: black;
}

.border-light-grey {
  border-color: #e5e5e5;
}

.border-medium-grey {
  border-color: #ccc;
}

.border-grey {
  border-color: #999;
}

.border-dark-grey {
  border-color: #2222;
}

.border-white,
.border-fff {
  border-color: #fff;
}

.no-border-radius {
  border-radius: 0;
}

.radius3 {
  border-radius: 3px;
}

.radius5 {
  border-radius: 5px;
}

.radius10 {
  border-radius: 10px;
}

